import { createApp } from 'vue'
import TDesign from 'tdesign-mobile-vue'
import './style.scss'
import App from './App.vue'

import 'tdesign-mobile-vue/es/style/index.css'
import 'vue-waterfall-plugin-next/dist/style.css'
import { setupStore } from './store'
import { setupRouter } from './router'
import { setupUserLogin } from './utils/user/login.ts'
import gp from './plugins/gp'

import { createHead, VueHeadMixin } from '@unhead/vue'
import { setupDeviceId } from './utils/common/fingerprintjs.ts'
import Vue3TouchEvents, { type Vue3TouchEventsOptions } from 'vue3-touch-events'

const app = createApp(App)
app.use(TDesign)
app.use<Vue3TouchEventsOptions>(Vue3TouchEvents, {})
setupDeviceId()
setupStore(app)
gp.install(app)
setupRouter(app)
app.use(createHead())
app.mixin(VueHeadMixin)
setupUserLogin(app).then()

app.mount('#app')
