import { RouteRecordRaw } from 'vue-router'
import MobileLayout from '/@/layout/mobile/index.vue'

export const resourcePackageRoutes: RouteRecordRaw[] = [
  {
    path: '/resource/package',
    name: 'ResourcePackageRoot',
    component: MobileLayout,
    meta: {
      title: 'CC闪传·资源包',
      icon: 'home-2-line',
    },
    children: [
      {
        path: '/resource/package/:id',
        name: 'ResourcePackageIndex',
        component: () => import('/@/pages/resource/package/index/index.vue'),
        meta: {
          title: 'CC闪传·资源包详情',
          icon: 'home-2-line',
          hideBottomTabBar: true,
        },
      },
      {
        path: '/resource/package/:resource_package_id/photo/:paragraph_id',
        name: 'ResourcePackageParagraphPhotoIndex',
        component: () => import('/@/pages/resource/package/paragraph/photo/index.vue'),
        meta: {
          title: 'CC闪传·资源包图片',
          icon: 'home-2-line',
          hideBottomTabBar: true,
        },
      },
      {
        path: '/resource/package/:resource_package_id/video/:paragraph_id',
        name: 'ResourcePackageParagraphVideoIndex',
        component: () => import('/@/pages/resource/package/paragraph/video/index.vue'),
        meta: {
          title: 'CC闪传·资源包视频',
          icon: 'home-2-line',
          hideBottomTabBar: true,
        },
      },
    ],
  },
]
