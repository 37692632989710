<script setup lang="ts">
import { getDeviceClassNames } from '/@/utils/common/device.ts'
import { Sniffer } from 'xgplayer'

let vh = 0
const adapt_vh = () => (vh = window.innerHeight * 0.01)
window.addEventListener('resize', adapt_vh)
adapt_vh()

const class_names: Record<string, boolean> = { ...getDeviceClassNames() }
</script>

<template>
  <div
    class="ccgc-root-container"
    :class="class_names"
    id="ccgc-root-container"
    :device="Sniffer.device"
    :browser="Sniffer.browser"
    :weixin="Sniffer.isWeixin"
    :style="{ '--vh': `${vh}px` }"
  >
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<style scoped lang="scss">
body {
  .ccgc-root-container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
}
</style>
