import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '../layout/index/index.vue'
import MobileLayout from '../layout/mobile/index.vue'
import { base } from '../config'
import { setupWechatJsApi } from '../utils/wechat/jsapi.ts'
import { App } from 'vue'
import { useSeoMeta } from '@unhead/vue'
import { previewFileRoutes } from './pages/preview.ts'
import { resourceSquareRoutes } from './pages/resource-square.ts'
import { resourcePackageRoutes } from './pages/resource-package.ts'
import { resourceLibraryRoutes } from './pages/resource-library.ts'

export const constantRoutes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'LoginRoot',
    meta: {
      title: '登陆资源库',
      icon: 'home-2-line',
    },
    children: [
      {
        path: 'xwg/:ccid',
        name: 'LoginXwgIndex',
        component: () => import('/@/pages/public/login/xwg/index.vue'),
        meta: {
          title: '登陆资源库',
          icon: 'home-2-line',
        },
      },
      {
        path: 'space/:token',
        name: 'LoginSpaceIndex',
        component: () => import('/@/pages/public/login/space/index.vue'),
        meta: {
          title: '登陆资源库',
          icon: 'home-2-line',
        },
      },
    ],
  },
  {
    path: '',
    name: 'Error',
    component: MobileLayout,
    meta: {
      title: '错误',
      hidden: true,
    },
    children: [
      {
        path: 'error',
        name: 'Error',
        component: () => import('/@/pages/public/error/index.vue'),
        meta: {
          title: '错误',
          hidden: true,
          no_navigate_bar: true,
        },
      },
      {
        path: '403',
        name: 'Error403',
        component: () => import('/@/pages/public/error/index.vue'),
        meta: {
          title: '无权限',
          hidden: true,
          no_navigate_bar: true,
        },
      },
      {
        path: '404',
        name: 'Error404',
        component: () => import('/@/pages/public/error/index.vue'),
        meta: {
          title: '页面不存在',
          hidden: true,
          no_navigate_bar: true,
        },
      },
    ],
  },
]

export const asyncRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Index',
    component: () => import('/@/pages/index/index.vue'),
    meta: {
      title: '首页',
      icon: 'home-2-line',
    },
  },
  {
    path: '/open',
    name: 'OpenRoot',
    component: Layout,
    meta: {
      title: '前往',
      icon: 'home-2-line',
    },
    children: [
      {
        path: 'wechat/miniprogram',
        name: 'OpenWechatMiniProgram',
        component: () => import('/@/pages/public/open/wechat-mini-program/index.vue'),
        meta: {
          title: '前往微信小程序',
          icon: 'home-2-line',
        },
      },
    ],
  },
  ...resourcePackageRoutes,
  ...resourceLibraryRoutes,
  ...resourceSquareRoutes,
  ...previewFileRoutes,
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
    name: 'NotFound',
    meta: {
      title: '404',
      hidden: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(base),
  routes: constantRoutes,
})

const addRouter = (routes: RouteRecordRaw[]) => {
  routes.forEach((route: RouteRecordRaw) => {
    if (!router.hasRoute(route.name)) router.addRoute(route)
    if (route.children) addRouter(route.children)
  })
}

export const setupRouter = (app: App<Element>) => {
  addRouter(constantRoutes)
  addRouter(asyncRoutes)
  app.use(router)

  router.beforeEach((to, from, next) => {
    next()
    setupWechatJsApi(app)
    const title = to.meta.title || ''
    useSeoMeta({
      title,
      description: '',
      ogDescription: '',
      twitterDescription: '',
      ogTitle: title,
      twitterTitle: title,
      ogImage: '',
      twitterImage: '',
      articleAuthor: ['CC闪传'],
      twitterSite: 'CC闪传资源广场',
      ogSiteName: 'CC闪传资源广场',
      ogUrl: router.resolve(to).href,
      ogType: 'website',
      twitterCreator: 'CC闪传',
      twitterCard: 'app',
    })
  })

  return router
}

export default router
