<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PreviewFileLayout',
  data() {
    return {
      innerHeight: `${window.innerHeight}px`,
    }
  },
  mounted() {
    window.addEventListener('resize', this.update_container_height)
  },
  methods: {
    update_container_height() {
      this.innerHeight = `${window.innerHeight}px`
    },
  },
})
</script>

<template>
  <div class="preview-file-layout" :style="{ height: innerHeight }">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<style scoped lang="scss">
.preview-file-layout {
  width: 100vw;
  height: var(--100vh, 100vh);
  overflow: auto;

  :deep(.preview-file-loading-container) {
    display: flex;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
}
</style>
