<script lang="ts">
import { defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import MobileHeaderLayout from './layout/header/index.vue'

export default defineComponent({
  name: 'MobileLayout',
  components: { MobileHeaderLayout },
  data() {
    const route = useRoute()
    const router = useRouter()
    return {
      route,
      router,
      innerHeight: `${window.innerHeight}px`,
    }
  },
  computed: {
    navigate_bar_title() {
      const title = this.route.meta?.title
      if (typeof title === 'string' && title.trim().length > 0) {
        return title
      }
      return '首页'
    },
    has_navigate_bar() {
      return !this.route.meta?.no_navigate_bar
    },
  },
  mounted() {
    window.addEventListener('resize', this.update_container_height)
  },
  methods: {
    update_container_height() {
      this.innerHeight = `${window.innerHeight}px`
    },
    has_back_route() {
      return this.router.getRoutes().length > 0
    },
    navigate_back() {
      const history = this.router.getRoutes()
      if (history.length > 0) this.router.back()
      else this.router.replace('/')
    },
  },
  watch: {},
})
</script>

<template>
  <div class="resource-square-mobile-layout-container-root" :style="{ height: innerHeight }">
    <div class="resource-square-mobile-layout-container-wrapper">
      <div class="resource-square-mobile-layout-container" :class="{ 'no-mobile-header-layout': !has_navigate_bar }">
        <mobile-header-layout v-if="has_navigate_bar" :title="navigate_bar_title" />
        <div class="resource-square-mobile-layout-container-main">
          <router-view v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@media screen and (max-width: 500px) {
  .resource-square-mobile-layout-container-root {
    width: 100vw;
    background: none !important;

    .resource-square-mobile-layout-container-wrapper {
      .resource-square-mobile-layout-container {
        --resource-square-mobile-layout-container-outer-padding: 0;
        background-color: white !important;
        width: 100vw !important;
        height: 100% !important;
        position: absolute !important;
        margin: 0 !important;
        border-radius: 0 !important;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.resource-square-mobile-layout-container-root {
  width: 100vw;
  background: url('/images/bg.jpg') no-repeat center center / cover;
  position: absolute;

  .resource-square-mobile-layout-container-wrapper {
    background-color: #ffffff4d;
    width: 100%;
    height: 100%;
    position: absolute;

    .resource-square-mobile-layout-container.no-mobile-header-layout {
      --resource-square-mobile-layout-container-header-height: 0;
      .resource-square-mobile-layout-container-main {
        height: 100%;
        overflow: auto;
      }
    }

    .resource-square-mobile-layout-container {
      --resource-square-mobile-layout-container-outer-padding: 20px;
      max-width: 500px;
      overflow: hidden;
      background-color: white;
      margin: var(--resource-square-mobile-layout-container-outer-padding) auto;
      height: calc(var(--100vh, 100vh) - (var(--resource-square-mobile-layout-container-outer-padding) * 2));
      border-radius: var(--td-swiper-border-radius, 10px);
      --resource-square-mobile-layout-container-top-padding: 15px;
      --resource-square-mobile-layout-container-bottom-padding: 5px;
      --resource-square-mobile-layout-container-horizontal-padding: 15px;
      --resource-square-mobile-layout-container-header-height: calc(
        24px + var(--resource-square-mobile-layout-container-top-padding) + var(--resource-square-mobile-layout-container-bottom-padding)
      );
      --resource-square-mobile-layout-container-main-height: calc(100% - var(--resource-square-mobile-layout-container-header-height, 0));
      --resource-square-mobile-layout-container-main-horizontal-padding: var(--resource-square-mobile-layout-container-horizontal-padding);

      &-main {
        height: var(--resource-square-mobile-layout-container-main-height);
        overflow: auto;
      }

      :deep() {
        .t-popup {
          .t-form {
            margin-bottom: 20px;
          }

          .t-button.t-button--block {
            .t-button__content {
              display: flex;
              align-items: center;
              .t-icon {
                margin-right: 5px;
                font-size: 1.2em;
              }
            }
          }
        }
      }
    }
  }
}
</style>
