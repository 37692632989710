import request from '/@/utils/common/request.ts'

export async function getResourceLibraryLoginInfo() {
  return request({
    url: '/Api24/ResourceLibrary/get_login_info',
    method: 'post',
    data: {},
  })
}

export async function loginResourceLibraryXwgUser(ccid: number) {
  return request({
    url: '/Api24/ResourceLibrary/login_xwg_user',
    method: 'post',
    data: {
      ccid,
    },
  })
}

export async function getResourceLibraryList() {
  return request({
    url: '/Api24/ResourceLibrary/get_library_list',
    method: 'post',
  })
}

export async function getResourceLibraryInfo(resource_library_id: string) {
  return request({
    url: '/Api24/ResourceLibrary/get_library_info',
    method: 'post',
    data: { resource_library_id },
  })
}

export async function getResourceLibraryTagList(resource_library_id: string) {
  return request({
    url: '/Api24/ResourceLibrary/get_library_tag_list',
    method: 'post',
    data: { resource_library_id },
  })
}

export type ResourceLibraryPackage = {
  click_count: number
  collect_count: number
  comment_count: number
  count: number
  create_at: number
  download_count: number
  forward_count: number
  fsize: number
  is_auditor: boolean
  is_sender: boolean
  liked_count: number
  modify_at: string
  mp: object
  name: string
  public_web_url: string
  publish_at: string
  resource_library_id: string
  resource_library_name: string
  resource_library_package_id: string
  resource_library_tag_id: string
  resource_library_tag_name: string
  resource_package_id: string
  sender_faceimg: string
  sender_name: string
  sender_suid: string
  tag_list: any[]
  tag_names: string[]
  thumb_url: string
}

export async function getResourceLibraryPackageList(data: {
  tag_id: string
  recommend?: boolean
  keyword?: string
  page_index?: number
  page_size?: number
  order_field?: number
  order_direction?: number
}): Promise<{
  status: 0
  message: string
  list: ResourceLibraryPackage[]
  total: number
  total_page: number
  page_index: number
  page_size: number
}> {
  return request({
    url: '/Api24/ResourceLibrary/get_library_package_list',
    method: 'post',
    data,
  })
}

export async function getResourceLibraryCollectPackageList(data: { name?: string; page_index?: number; page_size?: number }): Promise<any> {
  return request({
    url: '/Api24/ResourceLibrary/get_collect_package_list',
    method: 'post',
    data,
  })
}

export async function searchResourceLibraryPackageList(
  data: Partial<{
    resource_library_id: string
    keyword: string
    collect: boolean
    sender_suid: string
    sender_name: string
    min_publish_at: string
    max_publish_at: string
    min_create_at: string
    max_create_at: string
    min_modify_at: string
    max_modify_at: string
    page_index: number
    page_size: number
  }>
): Promise<any> {
  return request({
    url: '/Api24/ResourceLibrary/search_package_list',
    method: 'post',
    data,
  })
}

export async function collectResourceLibraryPackage(resource_package_id: string): Promise<any> {
  return request({
    url: '/Api24/ResourceLibrary/collect_package',
    method: 'post',
    data: { resource_package_id },
  })
}

export async function removeCollectResourceLibraryPackage(resource_package_id: string): Promise<any> {
  return request({
    url: '/Api24/ResourceLibrary/remove_collect_package',
    method: 'post',
    data: { resource_package_id },
  })
}
