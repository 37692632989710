import { LocationQuery } from 'vue-router'
import router from '/@/router'

export const baseURL = import.meta.env.VITE_APP_BASE_URL

export const SITE_URL = is_production_environment() ? 'i.xwg.cc' : 'i3.xwg.cc'

export const POPUP_ATTACH_CSS_PATH = '.resource-square-layout-container'

export function is_production_environment() {
  return baseURL.includes('i.xwg.cc')
}

export function is_development_environment() {
  return baseURL.includes('i3.xwg.cc')
}

export function get_query_params(query: LocationQuery) {
  const map: Record<string, string> = {}
  for (let key in query) {
    const value = query[key]
    if (Array.isArray(value)) {
      map[key] = decodeURIComponent(value.join(','))
    } else {
      map[key] = decodeURIComponent(value?.toString() || '')
    }
  }
  return map
}

export function navigate_to_error(error: any) {
  router
    .replace({
      name: 'Error',
      query: {
        status: error.status,
        message: error.message,
        request_id: error.request_id,
      },
    })
    .then(() => {})
  return { data: undefined, list: [], total: 0 }
}
