import { defineStore } from 'pinia'
import { getResourcePackageLayoutDisplayMode } from '/@/pages/resource/square'
import { RESOURCE_PACKAGE_LAYOUT_DISPLAY_MODE_LABELS, setResourcePackageLayoutDisplayMode } from '/@/pages/resource/square'
import { CcActionSheetItem } from '/@/components/cc-action-sheet/index.vue'
import { useRouter } from 'vue-router'

export const useResourcePackageDisplayModeStore = defineStore('resource-package-display-mode', {
  state() {
    const router = useRouter()

    return {
      router,
      value: getResourcePackageLayoutDisplayMode(),
    }
  },
  actions: {
    modify(value: number) {
      this.value = value
      setResourcePackageLayoutDisplayMode(value)
      window.location.reload()
    },
    getChangeActionSheets(): CcActionSheetItem[] {
      const displayModeValue = getResourcePackageLayoutDisplayMode()
      const that = this
      const items: CcActionSheetItem[] = []
      for (let i = 0; i < RESOURCE_PACKAGE_LAYOUT_DISPLAY_MODE_LABELS.length; i++) {
        if (displayModeValue === i) continue
        const label = RESOURCE_PACKAGE_LAYOUT_DISPLAY_MODE_LABELS[i]
        items.push({
          label: `设为${label}`,
          callback: () => that.modify(i),
        })
      }
      return items
    },
  },
})
