import Layout from '/@/layout/index/index.vue'
import { RouteRecordRaw } from 'vue-router'

export const resourceSquareRoutes: RouteRecordRaw[] = [
  {
    path: '/resource/square',
    name: 'ResourceSquareIndex',
    component: Layout,
    meta: {
      title: 'CC闪传·资源广场',
      icon: 'home-2-line',
    },
    children: [
      {
        path: 'recommend',
        name: 'ResourceSquareRecommendIndex',
        component: () => import('/@/pages/resource/square/recommend/index.vue'),
        meta: {
          title: 'CC闪传·资源广场·推荐',
          icon: 'home-2-line',
        },
      },
      {
        path: 'latest',
        name: 'ResourceSquareLatestIndex',
        component: () => import('/@/pages/resource/square/latest/index.vue'),
        meta: {
          title: 'CC闪传·资源广场·最新',
          icon: 'home-2-line',
        },
      },
      {
        path: 'collect',
        name: 'ResourceSquareCollectIndex',
        component: () => import('/@/pages/resource/square/collect/index.vue'),
        meta: {
          title: 'CC闪传·资源广场·收藏',
          icon: 'home-2-line',
        },
      },
      {
        path: 'search',
        name: 'ResourceSquareSearchIndex',
        component: () => import('/@/pages/resource/square/search/index.vue'),
        meta: {
          title: 'CC闪传·资源广场·搜索',
          icon: 'home-2-line',
        },
      },
    ],
  },
]
