import { RouteRecordRaw } from 'vue-router'
import PreviewFileLayout from '/@/layout/preview/index.vue'
import MobileLayout from '/@/layout/mobile/index.vue'

export const previewFileRoutes: RouteRecordRaw[] = [
  {
    path: '/preview',
    name: 'PreviewIndex',
    component: MobileLayout,
    meta: {
      title: '预览',
      icon: 'home-2-line',
    },
    children: [
      {
        path: 'photo',
        name: 'PreviewPhotoIndex',
        component: () => import('/@/pages/preview/photo/index.vue'),
        meta: {
          title: '预览图片',
          icon: 'home-2-line',
          no_navigate_bar: true,
        },
      },
      {
        path: 'video',
        name: 'PreviewVideoIndex',
        component: () => import('/@/pages/preview/video/index.vue'),
        meta: {
          title: '预览视频',
          icon: 'home-2-line',
          no_navigate_bar: true,
        },
      },
    ],
  },
  {
    path: '/preview/file/:id',
    name: 'PreviewFileIndex',
    component: PreviewFileLayout,
    meta: {
      title: '预览文件',
      icon: 'file',
    },
    children: [
      {
        path: 'pdf',
        name: 'PreviewFilePdf',
        component: () => import('/@/pages/preview/file/pdf/index.vue'),
        meta: {
          title: '预览PDF文件',
          icon: 'file-pdf',
        },
      },
      {
        path: 'docx',
        name: 'PreviewFileDocx',
        component: () => import('/@/pages/preview/file/docx/index.vue'),
        meta: {
          title: '预览Docx文件',
          icon: 'file-doc',
        },
      },
      {
        path: 'ms-office',
        name: 'PreviewFileMsOffice',
        component: () => import('/@/pages/preview/file/ms-office/index.vue'),
        meta: {
          title: '预览Office文件',
          icon: 'file-doc',
        },
      },
      {
        path: 'markdown',
        name: 'PreviewFileMarkdown',
        component: () => import('/@/pages/preview/file/markdown/index.vue'),
        meta: {
          title: '预览Markdown文件',
          icon: 'file',
        },
      },
      {
        path: 'text',
        name: 'PreviewFileText',
        component: () => import('/@/pages/preview/file/text/index.vue'),
        meta: {
          title: '预览文本文件',
          icon: 'file',
        },
      },
      {
        path: 'audio',
        name: 'PreviewFileAudio',
        component: () => import('/@/pages/preview/file/audio/index.vue'),
        meta: {
          title: '预览音频文件',
          icon: 'file',
        },
      },
    ],
  },
]
