import { isAndroid } from '@fingerprintjs/fingerprintjs'

export const STORAGE_NAME_DEVICE_ID = 'device_id'

export function isWechatBrowser() {
  return /micromessenger/i.test(navigator.userAgent)
}

export function isXwgBrowser() {
  return /XWG/i.test(navigator.userAgent)
}

export function isMobileDevice() {
  return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
}

export function isIosDevice() {
  return /IOS/i.test(navigator.userAgent)
}

export function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
}

export function getDeviceClassNames() {
  const touch_device = isTouchDevice()
  return {
    'touch-device': touch_device,
    'desktop-device': !touch_device,
    'mobile-device': isMobileDevice(),
    'android-device': isAndroid(),
    'ios-device': isIosDevice(),
    'wechat-browser': isWechatBrowser(),
    'xwg-browser': isXwgBrowser(),
  }
}

export function getDeviceId() {
  const deviceId = localStorage.getItem(STORAGE_NAME_DEVICE_ID)
  if (deviceId && !deviceId.startsWith('ANONYMOUS-')) return deviceId
  let value = getRandomDeviceId()
  localStorage.setItem(STORAGE_NAME_DEVICE_ID, value)
  return value
}

export function getRandomDeviceId() {
  const seed = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let value = ''
  for (let i = 0; i < 20; i++) {
    value += seed[Math.floor(Math.random() * seed.length)]
  }
  return value
}

export async function sendXwgBrowserOpenMiniProgramEvent(config: { appid: string; username: string; path: string; type: number }) {
  if (window.xwg) {
    window.xwg.openMiniProgram(config.appid, config.username, config.path, config.type)
    return true
  }

  if (window?.webkit?.messageHandlers?.openMiniProgram) {
    window.webkit.messageHandlers.openMiniProgram.postMessage(JSON.stringify({ ...config, userName: config.username }))
    return true
  }

  return false
}

export async function forwardXwgBrowserOpenMiniProgramEvent(config: {
  userName: string
  path: string
  imagePath: string
  webpageUrl: string
  withShareTickt: string
  miniprogramType: string
  miniProgramType: string
  scene: string
}) {
  config = { ...config, userName: config.userName }
  if (window.xwg) {
    window.xwg.forwardMiniProgram(JSON.stringify(config))
    return true
  }

  if (window?.webkit?.messageHandlers?.forwardMiniProgram) {
    window.webkit.messageHandlers.forwardMiniProgram.postMessage(JSON.stringify(config))
    return true
  }

  return false
}
