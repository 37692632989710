import fingerPrintJS from '@fingerprintjs/fingerprintjs'
import { getRandomDeviceId, STORAGE_NAME_DEVICE_ID } from './device.ts'

export function setupDeviceId() {
  const visitor = localStorage.getItem('visitor')
  const value = localStorage.getItem(STORAGE_NAME_DEVICE_ID)
  if (value && visitor) {
    const visitorObject = JSON.parse(visitor)
    if (hexToBase62(visitorObject.visitorId) === value) return
  }
  getDeviceId().then().catch()
}

export async function getDeviceId() {
  return new Promise((resolve) => {
    fingerPrintJS
      .load()
      .then((fp) => fp.get())
      .then((result) => {
        localStorage.setItem(STORAGE_NAME_DEVICE_ID, hexToBase62(result.visitorId))
        resolve(hexToBase62(result.visitorId))
        localStorage.setItem('visitor', JSON.stringify(result))
      })
      .catch((error: any) => {
        console.error('get finger print visitor id error', error)
        resolve(getRandomDeviceId())
      })
  })
}

export function hexToBase64(hexString: string) {
  // 将16进制字符串转换为字节数组
  let bytes = []
  for (let i = 0; i < hexString.length; i += 2) {
    bytes.push(parseInt(hexString.substr(i, 2), 16))
  }

  // 将字节数组转换为Base64字符串
  return btoa(String.fromCharCode.apply(null, bytes))
}

export function hexToBase62(hex: string) {
  const base62Chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let decimalValue = BigInt('0x' + hex) // 将16进制字符串转换为十进制大整数
  let base62 = ''

  while (decimalValue > 0) {
    base62 = base62Chars[decimalValue % 62n] + base62
    decimalValue = decimalValue / 62n
  }

  return base62 || '0'
}
