<script lang="ts">
import { defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CcActionSheet, { CcActionSheetItem } from '/@/components/cc-action-sheet/index.vue'
import { ChevronLeftIcon, HomeIcon, EllipsisIcon } from 'tdesign-icons-vue-next'

export default defineComponent({
  name: 'MobileHeaderLayout',
  components: { CcActionSheet, ChevronLeftIcon, HomeIcon, EllipsisIcon },
  props: {
    title: {
      type: String,
      require: true,
      default: '',
    },
    rightMenus: {
      type: Array<CcActionSheetItem>,
      require: true,
      default: () => [],
    },
  },
  data() {
    const route = useRoute()
    const router = useRouter()
    return {
      route,
      router,
    }
  },
  computed: {
    has_back_route() {
      console.log(this.route.fullPath, this.route.meta.is_first_route)
      return !!this.route.meta.is_first_route
    },
  },
  methods: {
    navigate_back() {
      if (window.history.state.back) {
        this.router.back()
      } else {
        this.router.replace('/')
      }
    },
    showRightActions() {
      const items = this.rightMenus
      if (items && items.length < 1) return
      ;(this.$refs.actionSheet as any).open(items)
    },
  },
})
</script>

<template>
  <div class="resource-square-mobile-layout-container-header">
    <div class="resource-square-mobile-layout-container-header-left">
      <t-navbar :title="title" :fixed="false">
        <template #left>
          <slot name="left">
            <chevron-left-icon size="28px" @click="navigate_back" />
          </slot>
        </template>
        <template #right>
          <slot name="right">
            <ellipsis-icon size="24px" v-if="rightMenus && rightMenus.length > 0" @click="showRightActions" />
          </slot>
        </template>
      </t-navbar>
    </div>
    <div class="resource-square-mobile-layout-container-header-right"></div>
    <cc-action-sheet ref="actionSheet" />
  </div>
</template>

<style scoped lang="scss">
.resource-square-mobile-layout-container-header {
  border-bottom: 1px solid #f2f2f2;

  .t-navbar :deep(.t-navbar__left) {
    margin-left: 10px;
  }
  .t-navbar {
    width: 100%;
    color: var(--primary-text-color);
    --td-navbar-title-font-weight: normal;

    &__left {
      .navbar-left-icon {
        &:not(:last-child) {
          margin-right: 7px;
        }
      }

      .t-icon {
        color: var(--secondary-text-color);
        cursor: pointer;
      }
      a {
        color: var(--secondary-text-color);
      }
    }
  }
}
</style>
