import { defineStore } from 'pinia'
import { getResourceLibraryLoginInfo, loginResourceLibraryXwgUser } from '../../api/api24/resource/resource-library.ts'
import { navigate_to_error } from '../../utils/common'

export const useLibraryUserStore = defineStore('library-user', {
  state() {
    return {
      token: sessionStorage.getItem('token'),
      user: undefined as any,
    }
  },
  getters: {
    getToken: (state) => state.token,
    ccid: (state) => state.user?.ccid || 0,
    suid: (state) => state.user?.suid || '',
    union_id: (state) => state.user?.union_id || '',
    avatar: (state) => state.user?.avatar || 'https://cckj-avatar.cdn.xwg.cc/0',
    is_auditor: (state) => state.user?.is_auditor,
    ft_login_id: (state) => state.user?.ft_login_id || '',
  },
  actions: {
    async get_info() {
      if (this.user) return this.user
      const { data } = await getResourceLibraryLoginInfo().catch(navigate_to_error)
      this.user = data
      return data
    },
    async login_xwg_user(ccid: number) {
      const { data } = await loginResourceLibraryXwgUser(ccid).catch(navigate_to_error)
      if (!data?.access_token) return Promise.reject()
      await this.setToken(data.access_token)
    },
    async login_space_user(access_token: string) {
      await this.setToken(access_token)
    },
    async setToken(token: string) {
      this.token = token
      sessionStorage.setItem('token', token)
      await this.get_info()
    },
    async logout() {
      this.token = ''
      this.user = undefined
      sessionStorage.removeItem('token')
      window.location.href = 'about:blank'
      window.close()
    },
  },
})
