import Layout from '/@/layout/index/index.vue'
import { RouteRecordRaw } from 'vue-router'

export const resourceLibraryRoutes: RouteRecordRaw[] = [
  {
    path: '/resource/library/:resource_library_id',
    name: 'ResourceLibraryIndex',
    component: Layout,
    meta: {
      title: '资源库',
      icon: 'home-2-line',
    },
    children: [
      {
        path: '',
        name: 'ResourceLibraryIndex',
        component: () => import('/@/pages/resource/library/index.vue'),
        meta: {
          title: '推荐',
          icon: 'home-2-line',
        },
      },
      {
        path: 'recommend',
        name: 'ResourceLibraryRecommendIndex',
        component: () => import('/@/pages/resource/library/recommend/index.vue'),
        meta: {
          title: '推荐',
          icon: 'home-2-line',
        },
      },
      {
        path: 'latest',
        name: 'ResourceLibraryLatestIndex',
        component: () => import('/@/pages/resource/library/latest/index.vue'),
        meta: {
          title: '最新',
          icon: 'home-2-line',
        },
      },
      {
        path: 'collect',
        name: 'ResourceLibraryCollectIndex',
        component: () => import('/@/pages/resource/library/collect/index.vue'),
        meta: {
          title: '收藏',
          icon: 'home-2-line',
        },
      },
      {
        path: 'search',
        name: 'ResourceLibrarySearchIndex',
        component: () => import('/@/pages/resource/library/search/index.vue'),
        meta: {
          title: '搜索',
          icon: 'home-2-line',
        },
      },
    ],
  },
]
